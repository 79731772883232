exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-arbeid-jsx": () => import("./../../../src/pages/arbeid.jsx" /* webpackChunkName: "component---src-pages-arbeid-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-nyheter-dato-cms-article-slug-jsx": () => import("./../../../src/pages/nyheter/{DatoCmsArticle.slug}.jsx" /* webpackChunkName: "component---src-pages-nyheter-dato-cms-article-slug-jsx" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-tjenester-jsx": () => import("./../../../src/pages/tjenester.jsx" /* webpackChunkName: "component---src-pages-tjenester-jsx" */)
}

